export const ADMIN_INFO = {
    address: '245 - 247 Quan Hoa, Phường Quan Hoa, Quận Cầu Giấy, Hà Nội',
    phone: '0966688650',
    email: 'Office@nongnghiepso.com',
    website: 'https://www.nongnghiepso.com',
    map: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14896.536707335103!2d105.7848151!3d21.0273166!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xce6b843408caa651!2zSOG7o3AgdMOhYyB4w6MgTsO0bmcgbmdoaeG7h3AgU-G7kQ!5e0!3m2!1sen!2s!4v1672128536387!5m2!1sen!2s',
    mst: '0107711729',
    companyName: 'HTX Nông Nghiệp Số'
}

export const DIGITAL_LIBRARY = {
    title: "THƯ VIỆN SỐ CỘNG ĐỒNG",
    description: 'Với mục tiêu lan tỏa văn hóa đọc và nâng cao tri thức, là không gian tri thức mở, nơi mọi người có thể dễ dàng tiếp cận sách, tài liệu và kiến thức trực tuyến. Thư viện cung cấp kho tài nguyên phong phú, miễn phí, hỗ trợ học tập và nghiên cứu. Đây là nơi kết nối tri thức, góp phần xây dựng một cộng đồng phát triển bền vững.'
}