import api from "@/api"

export default {
    actions: {
        async ListCommunityLibraryDocument(_, { body, params }) {
            const response = await this.$axios({
                method: 'POST',
                url: api.ListCommunityLibraryDocument,
                data: body,
                params: params
            });
            return response.data;
        },

        async GetVillageByWardId(_, wardId) {
            const response = await this.$axios({
                method: 'GET',
                url: api.params('GetVillageByWardId', { id: wardId })
            });
            return response.data;
        },
        async GetStatisticDocument(_, data) {
            const response = await this.$axios({
                method: 'POST',
                url: api.params('GetStatisticDocument'),
                data: data
            });
            return response.data;
        },
        async GetCommunityLibraryDocument(_, documentId) {
            const response = await this.$axios({
                method: 'GET',
                url: api.params('GetCommunityLibraryDocument', { id: documentId })
            })
            return response.data;
        },
        async GetCommunityLibraryRating(_, { documentId, data }) {
            const response = await this.$axios({
                method: 'POST',
                url: api.params('GetCommunityLibraryRating', { id: documentId }),
                data: data,
            });
            return response.data;
        }

    }
}