import api from '@/api'

export default {
    state: {
        ward: {
            id: '0',
            slug: 'admin',
            name: 'Nông Nghiệp Số',
        },
        contact: {}
    },
    getters: {
        GetWard: (state) => {
            return state.ward
        }
    },
    mutations: {
        UpdateWard(state, newWard) {
            state.ward = newWard
        },
        GetWard: (state) => {
            return state.ward
        },
        UpdateContact(state, newContact) {
            state.contact = newContact
        }
    },
    actions: {
        async GetWard(_, slug) {
            const response = await this.$axios({
                method: 'GET',
                url: api.params('GetWard', {
                    slug: slug
                })
            })

            return response.data
        },
        async GetProvinceWard(_, id) {
            const response = await this.$axios({
                method: 'GET',
                url: api.params('GetProvinceWard', {
                    id: id
                })
            })

            return response.data
        },
        async GetContact(_, slug) {
            const response = await this.$axios({
                method: 'GET',
                url: api.params('GetContact', {
                    slug: slug
                }),
                params: { length: 10000 }
            })

            return response.data
        }
    }
}