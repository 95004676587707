import routes from "@/routes"
import { createWebHistory, createRouter } from "vue-router";
import store from "@/stores";
import { ADMIN_INFO } from "@/constants/common"

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {   
        return { top: 0 };
    }
});

const updateContact = async function (slug) {
    if (slug == 'admin') {
        store.commit('common/UpdateContact', ADMIN_INFO)
        return;
    }
    else {
        let response = await store.dispatch('common/GetContact', slug)
        if (response.code == 200) {
            store.commit('common/UpdateContact', response.data.entry)
        }
    }
}
router.beforeEach(async (routeTo, routeFrom, next) => {
    let slugTo = routeTo.params.slug
    let slugStore = store.state.common.ward.slug
    if (slugTo == slugStore) {
        await updateContact(slugTo)
        return next()
    }
    if (slugTo == 'admin') {
        store.commit('common/UpdateWard', { id: 0, slug: 'admin', alias: 'Admin', name: 'Nông nghiệp số' })
        await updateContact(slugTo)
        return next()
    }
    else {
        let response;
        if (slugTo) {
            response = await store.dispatch('common/GetWard', slugTo)
        }
        if (response && response.code == 200) {
            store.commit('common/UpdateWard', response.data.entry)
            await updateContact(slugTo)
            return next()
        }
        else {
            if (!routeTo.path.includes(slugStore)) {
                let path = routeTo.path
                if (path == '/' || !path) {
                    path = '/' + slugStore + '/home'
                    next({ path })
                }
                else {
                    path = '/' + slugStore + routeTo.path
                    return next({ path })
                }
            }
            else {
                return next()
            }
        }
    }
});

router.beforeEach((to, from, next) => {
    if (to.path === '/admin/digital-library/list') {
        next("admin/home");
    } else {
        next();
    }
});

export default router;
