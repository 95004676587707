import moment from "moment";
import "moment/locale/vi";
moment.locale("vi"); 

export default (app) => {
	app.config.globalProperties.$filters = {
		dateTime: (value, format = "DD/MM/YYYY HH:mm") => {
			return moment(value).utcOffset(7).format(format);
		},
		date: (value) => {
			return moment(value).utcOffset(7).format("DD/MM/YYYY");
		},
		htmlToText: (content) => {
			content ??= ''
			return content.replace(/<[^>]*>/g, "").replaceAll("&nbsp;", "");
		},
		money: (money) => {
			if (money === undefined || money === null || isNaN(money)) {
				return "Miễn phí";
			}
			return money.toLocaleString("en-US").replace(/,/g, ".") + "đ";
		},
		dateTimeHour: (value) => {
			const now = moment();
			const createdAt = moment(value).utcOffset(7);
			const diffHours = now.diff(createdAt, "hours");

			if (diffHours < 24) {
				return createdAt.fromNow(); 
			} else {
				return createdAt.format("DD/MM/YYYY HH:mm");
			}
		}

	};
};
